.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    z-index: 100;
}

.footer p {
    color: #475657;
    font-size: 0.8em;
    margin-bottom: 5px;
}

.footer p a {
    color: #9984D4;
}