.home {
    padding: 100px 5%;
    display: flex;
    justify-content: space-around;
    align-items: start;
}

.home-content, .home-hero {
    width: 50%;
}

.home-content {
    padding: 3%;
    line-height: 1.5;
}

.home-content h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2em;
    margin-bottom: 1em;
    color: #475657;
}

.home-content p {
    color: #475657;
    margin-bottom: 1em;
}

.home-content p ul {
    padding-left: 20px;
    margin-top: 5px;
}

.home-content p a {
    color: #9984D4;
}

.home-content button {
    background-color: #EF5B5B;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 1em;
    transition: 0.3s;
}

.home-content button:hover {
    scale: 1.02;
}

.home-hero img {
    width: 100%;
}

@media (max-width: 420px) {
    .home {
        flex-direction: column;
    }
    .home-content, .home-hero {
        width: 100%;
    }
    .home-hero {
        margin-bottom: 10px;
    }
    .home-content {
        order: 2;
    }
}